import http from '../clients/Axios'

export default class ItemSupplierService {
  async paginate(pageNumber, pageSize = 10, sortField, sortOrder, filters) {
    pageNumber = pageNumber === undefined ? 1 : pageNumber
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    let filters_param = ''

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (filters[el].value) {
          if (el === 'item_sku' || el === 'item_name') {
            filters_param = filters_param + '&' + el + '=' + filters[el].value.trim()
          } else {
            filters_param = filters_param + '&' + el + '=' + filters[el].value
          }
        }
      })
    }

    const res = await http.get(
      `/itemsupplier/paginate?page=${pageNumber}&size=${pageSize}&sort=${sort}${filters_param}`
    )

    return res.data
  }

  async paginatebysupplier(
    pageNumber,
    pageSize = 10,
    sortField,
    sortOrder,
    filters,
    supp_id
  ) {
    pageNumber = pageNumber === undefined ? 1 : pageNumber
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    let filters_param = ''

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (filters[el].value) {
          filters_param = filters_param + '&' + el + '=' + filters[el].value
        }
      })
    }

    const res = await http.get(
      `/itemsupplier/paginate?supp_id=${supp_id}&page=${pageNumber}&size=${pageSize}&sort=${sort}${filters_param}`
    )

    return res.data
  }

  async get(supp_id) {
    const res = await http.get(`/itemsupplier?supp_id=${supp_id}`)
    return res
  }

  async getList(supp_id, keyword = '', price = '') {
    if (price) {
      const res = await http.get(
        `/itemsupplier/list?supp_id=${supp_id}&keyword=${keyword}&price_min=${price}`
      )
      return res.data
    } else {
      const res = await http.get(
        `/itemsupplier/list?supp_id=${supp_id}&keyword=${keyword}`
      )
      return res.data
    }
  }

  async getById(param) {
    param = param ? param : ''
    const res = await http.get(`/itemsupplier/${param}`)
    return res
  }

  async add(form) {
    const res = await http.post('/itemsupplier', form)
    return res
  }

  async update(id, form) {
    const res = await http.put(`/itemsupplier/${id}`, form)
    return res
  }

  async delete(itemsupplier) {
    const res = await http.delete(`/itemsupplier/${itemsupplier}`)
    return res
  }
}
